import * as React from 'react';
import Link, { LinkProps } from '@mui/joy/Link';
import { Link as GatsbyLink } from 'gatsby';
import { useSiteMetadata } from '../../hooks/use-site-metadata';

export const Title: React.FC<LinkProps> = (props) => {
  const { sx, ...otherProps } = props;
  const { siteTitle } = useSiteMetadata();

  return (
    <Link
      component={GatsbyLink}
      to={`/`}
      underline="none"
      sx={{
        color: 'inherit',
        fontFamily: 'Allura, cursive',
        fontSize: '1.6rem',
        lineHeight: 0.8,
        fontWeight: 600,
        letterSpacing: 'normal',
        textAlign: 'center',
        maxWidth: (theme) => theme.getCssVar('logo-width'),
        ...sx,
      }}
      {...otherProps}
    >
      {siteTitle}
    </Link>
  );
};
