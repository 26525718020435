import React from 'react';

import ToggleButtonGroup, {
  SupportedValue,
  ToggleButtonGroupProps,
} from '@mui/joy/ToggleButtonGroup';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import Button, { ButtonProps } from '@mui/joy/Button';
import { NavSection } from '../../hooks/use-footer-navigation';

export type NavItemLinkProps<TState> = ButtonProps &
  GatsbyLinkProps<TState> & {
    label?: string;
    icon?: React.ReactNode;
  };

export type NavToggleProps<TValue extends SupportedValue> =
  ToggleButtonGroupProps<TValue> & {
    list: NavSection[];
    pathname: string;
    subPath: string;
    hash: string;
  };

function findIndex(
  pathname: string,
  hash: string
): string | undefined {
  switch (pathname) {
    case '/about/':
      return hash == '#contact' ? pathname + hash : pathname;
    case '/videos/music/':
    case '/videos/nothing-creative/':
      return pathname;
  }
  return pathname.startsWith('/videos/') ? '/videos/playlists/' : undefined;
}

const NavToggleGroup: React.FC<NavToggleProps<any>> = (props) => {
  const { list, subPath, hash, ...groupProps } = props;
  const value = findIndex(subPath, hash);
  return (
    //https://www.w3.org/WAI/ARIA/apg/example-index/menubar/menubar-navigation.html
    <ToggleButtonGroup
      role="menubar"
      variant="plain"
      size="sm"
      spacing={0.5}
      value={value}
      {...groupProps}
    >
      {list.map(({ heading: { name, link = '' }, items }) => (
        <Button component={GatsbyLink} key={name} value={link} to={link}>
          {name}
        </Button>
      ))}
    </ToggleButtonGroup>
  );
};

export default NavToggleGroup;
