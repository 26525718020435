import { graphql, useStaticQuery } from 'gatsby';
import { NavSection } from './use-footer-navigation';

type Props = {
  allNavDrawer: {
    nodes: NavSection[];
  };
};

export const useDrawerNavigation = () => {
  const data = useStaticQuery<Props>(graphql`
    query {
      allNavDrawer {
        nodes {
          section {
            heading {
              name
              nameLg
              icon
              iconLg
              link
            }
            items {
              name
              nameLg
              icon
              iconLg
              link
              isExternal
            }
          }
          heading {
            name
            nameLg
            icon
            iconLg
            link
          }
          items {
            name
            nameLg
            icon
            iconLg
            link
            isExternal
          }
        }
      }
    }
  `);

  return data.allNavDrawer.nodes.map((x) => x.section || x);
};
