import React from 'react';
import Box from '@mui/joy/Box';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import Drawer, { DrawerProps } from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';

import NavList, { NavListProps } from './NavList';

type NavDrawerProps = DrawerProps &
  NavListProps & {
    title: string;
    open: boolean;
    onClose: () => void;
  };

const MobileDrawer: React.FC<NavDrawerProps> = (props) => {
  const { title, open, onClose, ...drawerProps } = props;

  return (
    <Drawer size="sm" open={open} onClose={onClose} title={title}>
      <Box className="hvs-masthead" sx={{ flexShrink: 0 }}>
        <ModalClose sx={{ position: 'initial' }} />
        <DialogTitle>{title}</DialogTitle>
      </Box>
      <Divider />
      <NavList {...drawerProps} />
    </Drawer>
  );
};

export default MobileDrawer;
