import React from 'react';
import { PageProps } from 'gatsby';
import CssBaseline from '@mui/joy/CssBaseline';
import {
  extendTheme,
  CssVarsProvider,
  CssVarsThemeOptions,
  useColorScheme,
  createGetCssVar,
} from '@mui/joy/styles';

import tailwind from '../../colors/tailwind';
import { NavigatorType } from './nav/primary';
import Header from '../blocks/header';
import { Footer } from '../blocks/footer';

// primary: #E392AA
// secondary: #A4ADCC

// --yt-spec-text-primary: #0f0f0f;--yt-spec-text-primary-inverse: #fff;
// --yt-spec-text-primary: #f1f1f1;--yt-spec-text-primary-inverse: #0f0f0f;
// YouTube darker-dark-theme:
// --yt-spec-base-background: #0f0f0f;--yt-spec-raised-background: #212121;--yt-spec-menu-background: #282828;

const cssVarPrefix = 'hvs';
const getCssVar = createGetCssVar(cssVarPrefix);

const { pink, yellow } = tailwind;
const themeOptions: CssVarsThemeOptions = {
  cssVarPrefix,
  colorSchemes: {
    light: {
      palette: {
        primary: {
          ...pink,
        },
        neutral: {
          plainHoverBg: getCssVar('additive-background'),
        },
        warning: {
          ...yellow,
        },
      },
    },
    dark: {
      palette: {
        primary: {
          ...pink,
        },
        neutral: {
          plainHoverBg: getCssVar('additive-background'),
        },
        warning: {
          ...yellow,
        },
      },
    },
  },
  components: {
    JoyCardOverflow: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '--Card-radius': '12px',
          '& .gatsby-image-wrapper': {
            position: 'static !important',
            img: {
              borderRadius: 'var(--AspectRatio-radius)',
            },
          },
        }),
      },
    },
  },
  fontFamily: {
    body: getCssVar('fontFamily-fallback'),
    display: getCssVar('fontFamily-fallback'),
  },
};
const theme = extendTheme(themeOptions);

export const maxWidth = 210 * 6 + 5 * 8 + 24 * 2;

interface LayoutContext {
  frontmatter?: any;
  category?: NavigatorType;
}

interface LayoutProps {
  post?: { slug: string };
  all?: { nodes: any[] };
  grid?: { nodes: any[] };
  playlist?: { items: any[] };
}

const Layout: React.FC<PageProps<LayoutProps, LayoutContext>> = ({
  children,
  location,
}) => {
  return (
    <CssVarsProvider theme={theme} defaultMode="system">
      <CssBaseline enableColorScheme />
      <Header location={location} />

      {children}

      <Footer />
    </CssVarsProvider>
  );
};

export default Layout;
