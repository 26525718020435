import React, { useRef } from 'react';
import { WindowLocation } from '@reach/router';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { Title } from './title';
import TopNav from './top-nav';
import { useTopNavigation } from '../../hooks/use-top-navigation';
import MobileDrawer from '../layout/nav/drawer/MobileDrawer';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import { resetScrollFor } from '../layout/nav/utils';
import MacMenu from './dropdown-menu';

interface NavDesktopProps {
  /** An extended version of window.document which comes from @react/router */
  location: WindowLocation<WindowLocation['state']>;
}

const NavDesktop: React.FC<NavDesktopProps> = ({ location }) => {
  const { pathname, hash } = location;
  const { siteTitle } = useSiteMetadata();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleMobileToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const closeDrawer = (): void => setMobileOpen(false);

  const mainRef = useRef(null);
  const resetScroll = (): void => resetScrollFor(mainRef);

  // remove slashes at front and end:
  const pathMatch = /^(?:\/videos)?\/[^/]*\/?/.exec(pathname) || [''];
  const subPath = pathMatch[0];

  const navProps = {
    pathname,
    subPath,
    hash,
  };
  const drawerProps = {
    ...navProps,
    hash,
    title: siteTitle,
    open: mobileOpen,
    onClose: closeDrawer,
    onItemClick: () => {
      // use setTimeout to avoid flicker before Link change
      setTimeout(() => {
        closeDrawer();
        resetScroll();
      }, 0);
    },
  };

  const topNavigation = useTopNavigation();

  return (
    <>
      <Box id="masthead-container">
        <Box className="hvs-masthead">
          <IconButton
            aria-label="Open drawer"
            onClick={handleMobileToggle}
            sx={{
              display: { xs: 'flex', sm: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Title />
          {/* <MacMenu /> */}
          <TopNav
            list={topNavigation}
            {...navProps}
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          />
        </Box>
      </Box>
      <MobileDrawer {...drawerProps} />
    </>
  );
};

export default NavDesktop;
