import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Box from '@mui/joy/Box';
import Container from '@mui/joy/Container';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useFooterNavigation } from '../../hooks/use-footer-navigation';
import { Title } from './title';
import { getIcon } from '../icons';

export const Footer: React.FC = () => {
  const footerNavigation = useFooterNavigation();

  return (
    <Container>
      <Box component="footer" sx={{ py: 8 }} role="contentinfo">
        <Stack direction="column">
          <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
            {footerNavigation.map((section) => {
              const {
                heading: { name, link },
              } = section;

              return (
                <Stack
                  key={name}
                  direction="column"
                  alignItems="flex-start"
                  sx={{ mb: { xs: 4, sm: 0 } }}
                >
                  {name == 'Title' ? (
                    <Title sx={{ mb: 1 }} />
                  ) : link ? (
                    <Link
                      component={GatsbyLink}
                      to={link}
                      color="neutral"
                      underline="none"
                      variant="plain"
                      level="h3"
                      sx={{
                        fontSize: { xs: 'lg', sm: 'xl' },
                        mb: { xs: 1, sm: 1.5 },
                        p: 0.5,
                        whiteSpace: 'nowrap',
                        borderBottom: (theme) =>
                          `2px solid ${theme.palette.text.primary}`,
                        borderRadius: `6px 6px 0 0`,
                        marginInline: 'initial',
                      }}
                    >
                      {name}
                    </Link>
                  ) : (
                    <Typography
                      level="h3"
                      sx={{
                        fontSize: { xs: 'lg', sm: 'xl' },
                        mb: { xs: 1, sm: 1.5 },
                        p: 0.5,
                        whiteSpace: 'nowrap',
                        borderBottom: (theme) =>
                          `2px solid ${theme.palette.text.primary}`,
                      }}
                    >
                      {name}
                    </Typography>
                  )}
                  <List size="sm">
                    {section.items.map((item) => {
                      const { name, nameLg, icon = '', isExternal, link } = item;
                      const label = nameLg || name;
                      const iconComp = getIcon(icon);
                      const listProps = isExternal
                        ? { component: 'a', href: link }
                        : { component: GatsbyLink, to: link };
                      return (
                        <ListItem key={item.link}>
                          <ListItemButton {...listProps}>
                            {iconComp ? (
                              <ListItemDecorator>{iconComp}</ListItemDecorator>
                            ) : undefined}
                            {label}
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Stack>
              );
            })}
          </Stack>
          <Box sx={{ minHeight: '16px' }} />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            textAlign="center"
            alignItems="center"
            justifyContent="space-between"
          >
            <Title
              sx={{
                display: { xs: 'block', sm: 'none' },
                maxWidth: undefined,
              }}
            />
            <Typography level="body-sm">
              &copy; {new Date().getFullYear()} Drag Chow. All rights reserved.
            </Typography>
            <Typography level="body-sm">
              <Link
                component={GatsbyLink}
                color="neutral"
                to="/privacy"
                sx={{ marginInlineEnd: 2 }}
              >
                Privacy Policy
              </Link>
              <Link
                component={GatsbyLink}
                color="neutral"
                to="/terms"
                sx={{ marginInlineEnd: 2 }}
              >
                Terms
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};
