import { graphql, useStaticQuery } from 'gatsby';

export interface NavItem {
  name: string;
  nameLg?: string;
  icon?: string;
  iconLg?: string;
  link?: string;
  isExternal?: boolean;
  hideTab?: boolean;
};

export type NavSection = {
  section?: NavSection;
  heading: NavItem;
  items: NavItem[];
};

type Props = {
  allNavFooter: {
    nodes: NavSection[];
  };
};

export const useFooterNavigation = () => {
  const data = useStaticQuery<Props>(graphql`
    query {
      allNavFooter {
        nodes {
          section {
            heading {
              name
              nameLg
              icon
              iconLg
              link
            }
            items {
              name
              nameLg
              icon
              iconLg
              link
              isExternal
            }
          }
          heading {
            name
            nameLg
            icon
            iconLg
            link
          }
          items {
            name
            nameLg
            icon
            iconLg
            link
            isExternal
          }
        }
      }
    }
  `);

  return data.allNavFooter.nodes.map((x) => x.section || x);
};
