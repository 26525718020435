import React from 'react';
import SvgIcon from '@mui/joy/SvgIcon';
import BrunchDiningRoundedIcon from '@mui/icons-material/BrunchDiningRounded';
import Face3TwoToneIcon from '@mui/icons-material/Face3TwoTone';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';
import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded';
import SmartDisplayRoundedIcon from '@mui/icons-material/SmartDisplayRounded';
import SmartphoneTwoToneIcon from '@mui/icons-material/SmartphoneTwoTone';
import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded';
import TagRoundedIcon from '@mui/icons-material/TagRounded';
import {
  mdiFacebook,
  mdiFacebookMessenger,
  mdiInstagram,
  mdiSoundcloud,
  mdiTelevisionClassic,
  mdiTwitter,
  mdiYoutube,
} from '@mdi/js';
import mdiSnapchat from './snapchat';

export function createSvgIcon(
  path: React.ReactNode | string,
  displayName: string
): typeof SvgIcon {
  const pathComp = typeof path === 'string' ? <path d={path} /> : path;
  // @ts-ignore internal component
  function Component(props, ref) {
    return (
      <SvgIcon data-testid={`${displayName}Icon`} ref={ref} {...props}>
        {pathComp}
      </SvgIcon>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }

  // @ts-ignore internal component
  Component.muiName = SvgIcon.muiName;

  // @ts-ignore internal component
  return React.memo(React.forwardRef(Component));
}

export const FacebookIcon = createSvgIcon(mdiFacebook, 'Facebook');
export const FacebookMessengerIcon = createSvgIcon(
  mdiFacebookMessenger,
  'FacebookMessenger'
);
export const InstagramIcon = createSvgIcon(mdiInstagram, 'Instagram');
export const SoundCloudIcon = createSvgIcon(mdiSoundcloud, 'SoundCloud');
export const SnapchatIcon = createSvgIcon(mdiSnapchat, 'Snapchat');
export const TelevisionClassicIcon = createSvgIcon(
  mdiTelevisionClassic,
  'TelevisionClassic'
);
export const TwitterIcon = createSvgIcon(mdiTwitter, 'Twitter');
export const YouTubeIcon = createSvgIcon(mdiYoutube, 'YouTube');

export function getIcon(to?: string): React.ReactElement | undefined {
  switch (to) {
    // Socials
    case 'email':
    case 'mail':
      return <MailTwoToneIcon />;
    case 'facebook':
      return <FacebookIcon />;
    case 'messenger':
      return <FacebookMessengerIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'snapchat':
      return <SnapchatIcon />;
    case 'soundcloud':
      return <SoundCloudIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'youtube':
      return <YouTubeIcon />;
    // Pages
    case 'brunch':
      return <BrunchDiningRoundedIcon />;
    case 'home':
      return <HomeRoundedIcon />;
    case 'music':
      return <MusicNoteRoundedIcon />;
    case 'phone':
      return <SmartphoneTwoToneIcon />;
    case 'playlist':
      return <PlaylistPlayRoundedIcon />;
    case 'tag':
      return <TagRoundedIcon />;
    case 'tv':
      return <TelevisionClassicIcon />;
    case 'video':
      return <SmartDisplayRoundedIcon />;
    case 'videos':
      return <SubscriptionsRoundedIcon />;
    case 'woman':
      return <Face3TwoToneIcon />;
    default:
      return undefined;
  }
}
