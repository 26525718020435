import React from 'react';
import { Link } from 'gatsby';

import ListSubheader from '@mui/joy/ListSubheader';
import List, { ListProps } from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';

import { getIcon } from '../../../icons';
import { useDrawerNavigation } from '../../../../hooks/use-drawer-navigation';
import { NavItem, NavSection } from '../../../../hooks/use-footer-navigation';

export interface NavListProps extends ListProps {
  subPath: string;
  hash: string;
  onItemClick: () => void;
}

function _navItem(
  item: NavItem,
  subPath: string,
  hash: string,
  onItemClick: () => void
): JSX.Element {
  const { name, nameLg, icon, iconLg, link } = item;
  const label = nameLg || name;
  const iconComp = getIcon(iconLg || icon);
  // TODO: Fix handling for random hashes
  const isSelected = subPath + hash === link;

  if (!link) {
    return <ListSubheader key={`sub-${label}`} sticky>{label}</ListSubheader>;
  }

  return (
    <ListItem key={link}>
      <ListItemButton
        color="neutral"
        component={Link}
        to={link}
        onClick={onItemClick}
        selected={isSelected}
      >
        {iconComp ? (
          <ListItemDecorator>{iconComp}</ListItemDecorator>
        ) : undefined}
        {label}
      </ListItemButton>
    </ListItem>
  );
}

function NavList(props: NavListProps): JSX.Element {
  const { subPath, hash, onItemClick, ...listProps } = props;
  const drawerNavigation = useDrawerNavigation();

  const mapItems = (sections: NavSection[]) =>
    sections.map((section) => {
      const { heading, items } = section;
      const { name, nameLg } = heading;
      const label = nameLg || name;
      if (!items || items.length < 1) {
        return _navItem(heading, subPath, hash, onItemClick);
      }
      return (
        <React.Fragment key={`frag-${label}`}>
          <ListDivider />
          <ListSubheader sticky>{label}</ListSubheader>
          {items.map((item) => _navItem(item, subPath, hash, onItemClick))}
        </React.Fragment>
      );
    });

  return (
    <List
      component="nav"
      sx={(theme) => ({
        '--List-padding': '12px',
        '--ListItem-radius': theme.vars.radius.sm,
        overflow: 'auto',
        flex: 1,
      })}
      {...listProps}
    >
      {mapItems(drawerNavigation)}
    </List>
  );
}

export default NavList;
